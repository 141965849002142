import { useState } from "react";

export function useMemberModal(members: MemberType[]) {
  const [open, setOpen] = useState(false);
  const [member, setMember] = useState<MemberType>();

  const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    const id = event.currentTarget.id;
    const clickedMember =
      { ...members.filter((item) => item.uuid === id)[0] } || {};
    setMember(clickedMember);
    setOpen(true);
  };

  function handleCloseModal() {
    setOpen(false);
  }

  return { open, member, handleOpenModal, handleCloseModal };
}
