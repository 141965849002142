import { createContext, ReactNode, useContext } from "react";
import React from "react";
import { useMatchMedia } from "@/hooks";

const LocalStateContext = createContext({
  isTablet: false,
  isDesktop: false,
});
const LocalStateProvider = LocalStateContext.Provider;

interface Props {
  children: ReactNode;
}

function MatchMediaProvider({ children }: Props) {
  const isTablet = useMatchMedia("(min-width: 1024px)");
  const isDesktop = useMatchMedia("(min-width: 1280px)");

  return (
    <LocalStateProvider value={{ isTablet, isDesktop }}>
      {children}
    </LocalStateProvider>
  );
}

function useMatchMediaContext() {
  const all = useContext(LocalStateContext);
  return all;
}

export { MatchMediaProvider, useMatchMediaContext };
